<template>
  <div class="proposal__wrap">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params">
        <el-form-item label="" prop="name">
          <el-input clearable v-model="table.params.name" placeholder="请输入关键字"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询 </el-button>
        </el-form-item>
      </el-form>
      <el-button type="primary" icon="el-icon-plus" size="mini" @click="showEdit">添加学校</el-button>
    </div>
    <!-- 表格 -->
    <div class="table__wrap">
      <VTable
        has-pagionation
        title=''
        addText='添加'
        :field="field"
        :loading="table.loading"
        :data="table.data"
        :page='table.params.page'
        :pageSize='table.params.count'
        :total='table.total'
        :tree-props="{children: 'children', hasChildren: 'has_child'}"
        :hasPagionation="true"
        :default-expand-all="true"
        :showOverflowTooltip="true"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      >
        <template v-slot:image="{row}">
          <el-image 
            style="width: 100px; height: 50px"
            :src="row.image" 
            :preview-src-list="[row.image]"
            fit="cover">
          </el-image>
        </template>
        <template v-slot:campus_name="{row}">
          <span>{{row.name}}{{row.campus_name ? '—' + row.campus_name : ''}}</span>
        </template>
        <template v-slot:action="{row}">
          <el-button type="text" size="mini" @click="pushPage(row)">院系</el-button>
          <el-button type="text" icon="el-icon-edit" @click="showEdit(row)">编辑</el-button>
        </template>
      </VTable>
    </div>

    <edit ref="edit" @refresh="getTable"></edit>
    
  </div>
</template>

<script>
import {setStorage,getStorage} from '@/storage'
import VTable from '@/components/VTable';
import Edit from './components/Edit.vue'
import { mixinTable } from '@/mixins/table.js'
export default {
  name: 'SchoolList',
  mixins:[mixinTable],
  components: {
    VTable,
    Edit,
  },
  data() {
    return {
      field: [
        { name: "campus_name", label: "学校名称", hidden: false },
        { name: "update_time", label: "更新时间", hidden: false },
        { name: "action", label: "操作", fixed:"right", width: "160", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          name: "",
          page: 1,
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
    }
  },
  created() {
    this.table.params.page = getStorage('schoolPage');
    this.getTable();
  },
  methods: {
    getTable() {
      this.$http.get('/admin/school/list', { params:this.table.params }).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      if (!!row.id) {
        dom.getDetail(row)
      }
      dom = null
    },
    pushPage(row) {
      sessionStorage.setItem('schoolId', row.id)
      this.$router.push({
        path: '/faculty',
        query: {
          schoolId: row.id,
        }
      })
    },
    // pageSize 改变时会触发
    handleSizeChange(pageSize) {
      this.table.params.count = pageSize;
      this.table.params.page = 1;
      this.getTable();
    },
    handleCurrentChange(page) {
      this.table.params.page = page;
      setStorage('schoolPage',page)
      this.getTable();
    }
  }
}
</script>

<style scoped lang="scss">
  .proposal__wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px;

    .filter__wrap {
      padding: 10px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
    }

    .table__wrap {
      flex: 1;
      padding: 0;
      box-sizing: border-box;
    }
  }
</style>